import { Close } from "@mui/icons-material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import QRCode from "qrcode.react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";
import useAuth from "src/hooks/useAuth";
import { apiPost } from "src/services/apiService";
import useUser from "src/swr/use-user";
import { notifySuccess } from "src/utils/toast";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "calc(100% - 32px)",
      maxWidth: 350,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function TwoStepVerificationModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const { layout, setModalTwoStepVerificationModal } =
    useContext(LayoutContext);
  const [code2fa, setCode2f] = useState("");
  const [code, setCode] = useState("");
  const { initialize } = useAuth();
  const { mutate } = useUser();

  useEffect(() => {
    if (layout.modal2fa) {
      generation2FCode();
    }
  }, [layout.modal2fa]);

  const generation2FCode = async () => {
    const code = await apiPost("users/activate2fa", {});
    setCode2f(code);
  };

  const handleActive2FCode = async () => {
    try {
      console.log("handleActive2FCode", code);
      await apiPost("users/confirmActivate2fa", { code2fa: code });
      setCode2f("");
      notifySuccess("Verificação em duas etapas configurada com sucesso");
      await initialize();
      setModalTwoStepVerificationModal(false);
      mutate();
    } catch (error) {
      console.log(error);
      notifySuccess("Erro na validação do código");
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modal2fa}
      onClose={() => setModalTwoStepVerificationModal(false)}
      sx={{ overflow: "auto" }}
    >
      <Paper sx={{ p: 3 }} className={classes.paper}>
        <Stack direction={"column"} spacing={2}>
          <IconButton
            aria-label="close"
            onClick={() => setModalTwoStepVerificationModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <Stack direction={"row"} spacing={1}>
            <VerifiedUserIcon />
            <Typography>Configurar a verificação em duas etapas</Typography>
          </Stack>
          <Typography>
            Levamos sua segurança muito a sério, e é por isso que exigimos a
            verificação em duas etapas. Veja como configurá-lo:
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <Box className={classes.chip}>
              <Typography fontSize={12}>1</Typography>
            </Box>
            <Typography>Instalar Autenticador do Google</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Box className={classes.chip}>
              <Typography fontSize={12}>2</Typography>
            </Box>
            <Typography>Digitalize o código QR</Typography>
          </Stack>
          <Box
            sx={{ textAlign: "center" }}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <QRCode
              value={code2fa}
              size={180}
              bgColor="#ffffff"
              fgColor="#000000"
              level="L"
              includeMargin
              renderAs="svg"
            />
          </Box>

          <Stack direction={"row"} spacing={2}>
            <Box className={classes.chip}>
              <Typography fontSize={12}>3</Typography>
            </Box>
            <Typography>
              Digite o código de 6 dígitos do Autenticador abaixo
            </Typography>
          </Stack>
          <TextField
            id="code"
            name="code"
            label={t("Código")}
            placeholder="Digite o código de 6 digitos..."
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            type="text"
            variant="outlined"
          />
          <Stack direction={"row"} justifyContent="space-between" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => setModalTwoStepVerificationModal(false)}
            >
              {t("cancel")}
            </Button>
            <Button variant="contained" onClick={handleActive2FCode}>
              {t("save")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default TwoStepVerificationModal;
