import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import numeral from "numeral";
import { useContext, useEffect, useState } from "react";
import AccountContext from "src/contexts/AccountContext";
import SensitiveInfo from "./SensitiveInfo";

const BingoAnimation = () => {
  const { activeAccount } = useContext(AccountContext);
  const [currentValue, setCurrentValue] = useState(
    activeAccount.defaultCoinBalance
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const oldValue = currentValue;
    const newValue = activeAccount.defaultCoinBalance;

    if (oldValue !== newValue) {
      const animateValue = (start, end, duration) => {
        let startTime = null;

        const step = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = timestamp - startTime;
          const progressRatio = Math.min(progress / duration, 1);

          const value = Math.floor(start + (end - start) * progressRatio);
          setCurrentValue(value);

          if (progress < duration) {
            requestAnimationFrame(step);
          } else {
            setCurrentValue(end);
          }
        };

        requestAnimationFrame(step);
      };

      animateValue(oldValue, newValue, 1000);
    }
  }, [activeAccount.defaultCoinBalance]);

  return (
    <Typography
      whiteSpace="nowrap"
      fontSize={isMobile ? "0.85rem" : "1rem"}
      fontWeight={500}
    >
      <SensitiveInfo text={`$ ${numeral(currentValue).format("0,0.00")}`} />
    </Typography>
  );
};

export default BingoAnimation;
